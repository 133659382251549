import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-plugin-sanity-image"
import Layout from "../components/Layouts"
import SEO from "../components/SEO"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"

const BottomSpacer = styled.div`
  margin-bottom: 500px;
  @media (max-width: 420px) {
    margin-bottom: 600px;
  }
  @media (max-width: 380px) {
    margin-bottom: 600px;
  }
`
const TopGrid = styled.div`
  display: grid;
  
  width: 80%;
  margin: auto;
  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
`
const LogoImage = styled(Image)`
  width: 350px;

`

const LogoStyle = styled.div`
  text-align: center;
  font-style: italic;
  

  margin: auto;
`
const LogoInfoStyle = styled.div`
  padding: 30px;
  text-align: center;
  margin: auto;
  
`
const LogoUL = styled.ul`
  margin: 0;
  padding: 10px 0;
`
const LogoList = styled.li`
  margin: 5px 0;
  list-style: none;
`
const TextContainer = styled.div`
  margin: auto;
  margin-top: 50px;
  text-align: center;
`
const ParagraphGrid = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  @media (max-width: 950px) {
    grid-template-columns: 1fr 0.9fr;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
const DrJohnParagraphGrid = styled.div`
  margin: 10px auto;
  display: grid;
  grid-template-columns: 0.4fr 1fr;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`
const FirstParagraph = styled.div`
  margin: auto;
`
const PictureDiv = styled.div`
  margin: auto;
  padding: 10px;
`

const DrJohnDiv = styled.div`
  margin: auto;
  padding: 10px;
`
const VitvanAtTable = styled(Image)`
  width: 320px;

  @media (max-width: 550px) {
    width: 100%;
    padding: 10px;
  }
  @media (min-width: 700px) {
    width: 360px;
    padding: 10px;
  }
  @media (min-width: 900px) {
    width: 480px;
    padding: 10px;
  }
`
const DrJohn = styled(Image)`
  max-width: 400px;

  width: 320px;

  @media (max-width: 550px) {
    width: 100%;
    padding: 10px;
  }
  @media (min-width: 700px) {
    width: 360px;
    padding: 10px;
  }
  @media (min-width: 900px) {
    width: 480px;
    padding: 10px;
  }
`

const LocationText = styled.h5`
  padding: 100px 50px 30px;

  font-style: italic;
  text-align: center;
  margin-top: 80px;
  font-weight: bold;
`
const TextTitle = styled.h2`
  font-weight: bold;
`
const MemberContainer = styled.div`
  background-color: rgb(196, 195, 195);
  width: 95%;
  max-width: 1400px;
  margin: 40px auto;
  padding: 20px 0;
`
const MemberTitle = styled.h2`
  margin: auto;
  text-align: center;
  font-weight: bold;
`

const Line = styled.div`
  border-top: 2px solid #747474;
  width: 85%;
  margin: auto;
`

const MemberImage = styled(Image)`
  border-radius: 50%;
  margin: auto;
  height: 180px;
  width: 140px;
  object-fit: cover;
`

const MemberBio = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`
const MemberName = styled.p`
  font-size: 20px;
  font-weight: bold;
  text-decoration: underline;
`

const MemberText = styled.div`
  font-size: 18px;
  text-align: center;
  font-style: italic;
`

const About = ({ data }) => {
  const allSanityImages = data.allSanityImages
  const allSanityMember = data.allSanityMember.nodes

  function sortByProperty(property) {
    return function (a, b) {
      if (a[property] > b[property]) return 1
      else if (a[property] < b[property]) return -1

      return 0
    }
  }

  allSanityMember.sort(sortByProperty("rank"))

  return (
    <>
      <SEO
        title="Vitvan"
        description="The effort in the School of the Natural Order is to get acquainted with ourselves, develop ourselves, lay hold of that mighty dynamic power with which we function, and direct that power."
      />
      <Layout>
        <TopGrid>
          {allSanityImages.nodes.map((node, i) => {
            if (node.title === "Black and White Logo") {
              return (
                <LogoStyle key={node.title}>
                  <LogoImage {...node.image} alt={node.title} />
                  <div>
                    <h4 >
                      Sortem Suam Quisque Amet
                    </h4>
                    <h6>("Let each one love his own destiny")</h6>
                  </div>
                </LogoStyle>
              )
            }
          })}

          <LogoInfoStyle>
            <h6>
              The eagle, with a serpent coiled around its neck has been the
              school’s logo since its inception as the School of the Sacred
              Science in the 1920’s.
            </h6>
            <LogoUL>
              <h4>The serpent and eagle are ancient symbols:</h4>
              <LogoList>
                <h6 style={{ fontWeight: "bold" }}>
                  --The serpent representative of wisdom; its coiled form
                  suggestive of the power latent in the sacral center (the
                  Greeks called it the Power of the Christos).
                </h6>
              </LogoList>
              <LogoList>
                <h6 style={{ fontWeight: "bold" }}>
                  --The eagle represents ‘spiritual’ vision. It was supposed to
                  be the only creature that could look directly into the sun.
                </h6>
              </LogoList>
            </LogoUL>
            <h5>
              We use these symbols as our logo: the eagle (‘spiritual’ vision)
              carrying the serpent (Christos Power) to “great heights”, the
              ascent of the force to the crown center.
            </h5>
          </LogoInfoStyle>
        </TopGrid>
        <TextContainer>
          <ParagraphGrid>
            <FirstParagraph>
              <TextTitle>The Schools Beginning</TextTitle>
              <p>
                The School began in Los Angeles in 1922 and at that time was
                called the School of the Sacred Science... It was founded by
                Ralph M. DeBit who was born in Kansas in 1883. He was raised in
                a Methodist family with the Christian bible as his early
                religious training, and later as a young man, he studied
                Christian Science and various metaphysical teachings. He became
                a forest ranger in Idaho and said that it was there, in 1907,
                that he began to seriously undertake his self development with
                the study and practice of Eastern philosophy. It was not long
                afterwards, in Seattle, that DeBit met a man named A.K. Mozumdar
                who became his teacher. He spent seven years of work and study
                with him.
              </p>
            </FirstParagraph>
            {allSanityImages.nodes.map((node, i) => {
              if (node.title === "Vitvan") {
                return (
                  <PictureDiv>
                    <VitvanAtTable {...node.image} alt={node.title} />
                    <h4>
                      <strong>{node.title}</strong>
                    </h4>
                  </PictureDiv>
                )
              }
            })}
          </ParagraphGrid>
          <p>
            Toward the last half of the 19th century, there was a reappearance
            of the pre-Christian gnosis and the Wisdom Teaching as it had been
            presented in the Occident. It was upon this tradition that the
            School was based. It was also correlated with DeBit's perception and
            insight of Eastern teachings as he learned from Mozumdar, The School
            of the Sacred Science continued throughout the 1920s and '30s, and
            during this time, DeBit came to be known as Vitvan, which in
            Sanskrit means "one who knows." He traveled extensively, lecturing
            from coast to coast and became well known among metaphysical circles
            of the time. The School moved to various locations, and during the
            '30s established in Colorado. It was near the mountain town of
            Bailey that the School built a retreat called the Ashrama from which
            Vitvan would leave during the winter to travel and lecture, and
            return to in the summer to teach seminars and classes.
          </p>
          <DrJohnParagraphGrid>
            {allSanityImages.nodes.map((node, i) => {
              if (node.title === "Dr. John Gozzi") {
                return (
                  <DrJohnDiv>
                    <DrJohn {...node.image} alt={node.title} />
                    <h4>
                      <strong>{node.title}</strong>
                    </h4>
                  </DrJohnDiv>
                )
              }
            })}

            <FirstParagraph>
              <p>
                During this period of his life, Vitvan was growing increasingly
                dissatisfied with the presentation of the Wisdom Teachings in
                the older metaphysical, occult and mystical framework that he
                felt did not fit the emerging consciousness of the new cycle now
                dawning. He felt it had to be empirically correlated with
                findings of modern science, and that this would be the
                foundation for a new presentation of the ancient gnosis.
              </p>
              <p>
                After reading "Science and Sanity" by Alfred Korzybski, the
                creator of general semantics, he felt he had found the key he
                had been looking for----a method for rearticulating the Wisdom
                Teachings in language and perception suitable for and acceptable
                to modern man. In 1938, he went to Chicago and studied with
                Korzybski. He subsequently reorganized the School as the School
                of the Natural Order, and began to completely rewrite everything
                he had previously written to conform with the new structure he
                perceived.
              </p>

              <p>
                The current literature of the School of the Natural Order was
                either written as lessons in the 1940s, or transcribed from
                lectures that were recorded from 1952 until 1963. The School
                also moved to California and finally to Nevada. Having been a
                teacher of the ancient gnosis for over 50 years and completing
                his life's work of presenting it in a scientific framework,
                Vitvan passed on in 1964 at the age of 80.
              </p>
            </FirstParagraph>
          </DrJohnParagraphGrid>
        </TextContainer>
        <LocationText>
          **** The School of the Natural Order presently has two established
          locations: one in Baker, Nevada, and one in Denver, Colorado. There
          are also study groups in California and around the country. ****
        </LocationText>
        <MemberContainer>
          <MemberTitle>SNOC Members</MemberTitle>
          {allSanityMember.map((member, i) => {
            return (
              <>
                <MemberBio>
                  <MemberImage
                    {...member.image}
                    width={300}
                    height={300}
                    alt={member.name}
                  />
                  <div>
                    <MemberName>
                      {member.name} - {member.role}
                    </MemberName>
                    <MemberText>
                      {" "}
                      <BlockContent blocks={member._rawBio} />
                    </MemberText>
                  </div>
                </MemberBio>
                <Line />
              </>
            )
          })}
        </MemberContainer>

        {/* <MemberContainer>
          
          <MemberGrid>
            {allSanityMember.map((member, i) => {
              return (
                <MemberDiv key={`member-${member.name}`}>
                  <MemberImage
                    {...member.image}
                    width={500}
                    height={300}
                    alt={member.name}
                    
                  />
                  
                </MemberDiv>
              )
            })}
          </MemberGrid>
          <SubTitle>As Pictured above:</SubTitle>
          <BioGrid>
            {allSanityMember.map((member, i) => {
              return (
                <MemberBio key={`member-bio-${member.name}`}>
                  <MemberName>
                    {member.name}-{member.role}
                  </MemberName>
                  <MemberText>
                    {" "}
                    <BlockContent blocks={member._rawBio} />
                  </MemberText>
                </MemberBio>
              )
            })}
          </BioGrid>
        </MemberContainer> */}
        <BottomSpacer />
      </Layout>
    </>
  )
}
export default About

export const query = graphql`
  query AboutPageQuery {
    allSanityImages(
      filter: {
        title: { in: ["Black and White Logo", "Vitvan", "Dr. John Gozzi"] }
      }
    ) {
      nodes {
        title
        image {
          ...ImageWithPreview
        }
      }
    }
    allSanityMember {
      nodes {
        _rawBio(resolveReferences: { maxDepth: 10 })
        image {
          ...ImageWithPreview
        }
        role
        rank
        name
        slug {
          current
        }
      }
    }
  }
`
